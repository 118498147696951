import * as React from "react"
import { API_INDEXES_CS } from "../global/api"

import LayoutSubpage from "../layouts/layout_subpage"
import Indexes from "../components/Indexes/Indexes"

const IndexPage = () => (
  <LayoutSubpage title="Indeks odpowiedników cerkiewnosłowiańskich">
    <Indexes
      dataUrl={API_INDEXES_CS}
      title="Indeks odpowiedników cerkiewnosłowiańskich"
      language="cs"
    />
  </LayoutSubpage>
)

export default IndexPage
